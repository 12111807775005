import FundingDesktop from "./FundingDesktop";
import FundingMobile from "./FundingMobile";
import { useState, ChangeEventHandler, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import DebitCard from "../DebitCard/DebitCard";
import useApi from "../../api/api";

interface FundingPageInterface {
  paymentMethod: {
    t: string;
    token: string;
  };
  fiUiCustomization: {
    off_network_bg_primary: string;
    off_network_bg_secondary: string;
    off_network_button_color: string;
    off_network_text_color: string;
  };
}

interface CardFields {
  name: string;
  number: string;
  cvc: string;
  expiry: string;
}

export const FundingPage = ({
  paymentMethod,
  fiUiCustomization,
}: FundingPageInterface) => {
  const api = useApi(paymentMethod.token, paymentMethod.t);
  const [view, setView] = useState("start");
  // Desktop flow: "start" => "confirm" => "initiated" => "success / error"
  // Mobile flow:  "start" => "destinationCard" => "amount" => "confirm" => "initiated" => "success / error"

  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [transferId, setTransferId] = useState("");
  const [transferStatus, setTransferStatus] = useState("");

  const [sourceCard, setSourceCard] = useState<CardFields>({
    number: "",
    expiry: "",
    name: "",
    cvc: "", // Not used - needed for card display
  });

  const [destinationCard, setDestinationCard] = useState<CardFields>({
    number: "",
    expiry: "",
    name: "",
    cvc: "", // Not used - needed for card display
  });

  const [amount, setAmount] = useState<string>("");

  const handleSourceCardChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSourceCard({
      ...sourceCard,
      [event.target.name]: event.target.value,
    });
  };

  const handleDestinationCardChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDestinationCard({
      ...destinationCard,
      [event.target.name]: event.target.value,
    });
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value ? event.target.value : "");
  };

  const cardIsValid = (card: CardFields) => {
    if (
      card.number.length !== 16 ||
      card.name === "" ||
      card.expiry.length !== 4
    ) {
      return false;
    } else {
      return true;
    }
  };

  const amountIsValid = () => {
    if (amount.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const submitForm = async () => {
    setIsSubmitted(true);

    const transfer_details = {
      card_from_pan: sourceCard.number,
      card_to_pan: destinationCard.number,
      card_from_cardholder_name: sourceCard.name,
      card_to_cardholder_name: destinationCard.name,
      card_from_expiration_data: sourceCard.expiry,
      card_to_expiration_data: destinationCard.expiry,
      amount: parseFloat(amount),
    };

    try {
      const response = await api.post("card_to_card", transfer_details);
      setTransferId(response.data.id);
      setTransferStatus(response.data.status);
      setView("initiated");
    } catch (error: any) {
      setView("error");
      if (
        error?.response?.data ===
        "The 'FROM' debit card number is not supported."
      ) {
        setErrorMessage(
          `Your Funding Source card (${sourceCard.number.replace(
            /^.{12}/g,
            "************"
          )}) is not supported. Please try a different card.`
        );
      } else if (
        error?.response?.data === "The 'TO' debit card number is not supported."
      ) {
        setErrorMessage(
          `Your Payment Destination card (${destinationCard.number.replace(
            /^.{12}/g,
            "************"
          )}) is not supported. Please try a different card.`
        );
      } else {
        setErrorMessage("Something went wrong. Please try again later.");
      }
    } finally {
      setIsSubmitted(false);
    }
  };

  useEffect(() => {
    const pollForResult = async () => {
      try {
        const response = await api.get(`transfer/status/${transferId}`);
        setTransferStatus(response.data.status);
        if (response.data.status === "succeeded") {
          setView("success");
        }
        if (response.data.status === "failed") {
          setErrorMessage("The transfer was not successful.");
          setView("error");
        }
      } catch (error) {}
    };
    const interval = setInterval(() => {
      if (
        !(
          transferStatus === "" ||
          transferStatus === "succeeded" ||
          transferStatus === "failed"
        )
      ) {
        pollForResult();
      }
    }, 3000);
    setTimeout(() => {
      clearInterval(interval);
    }, 600000);
    return () => clearInterval(interval);
  }, [api, transferId, transferStatus]);

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const renderCard = (
    title: string,
    cardFields: CardFields,
    eventHandler: ChangeEventHandler
  ) => {
    return (
      <div style={{ width: "100%" }}>
        <Grid style={{ padding: "15px", border: "solid" }}>
          <h5
            style={{
              margin: "-25px 0 15px -5px",
              padding: "0 5px",
              backgroundColor: fiUiCustomization.off_network_bg_primary,
              width: "fit-content",
            }}
          >
            {title}
          </h5>
          {DebitCard(cardFields, eventHandler, isSubmitted)}
        </Grid>
      </div>
    );
  };

  const renderAmountInput = () => {
    return (
      <TextField
        required
        label="Amount to Transfer"
        value={amount}
        type="number"
        onChange={handleAmountChange}
        error={amount.length === 0 && isSubmitted}
        helperText={
          amount.length === 0 && isSubmitted
            ? "Please enter a valid amount"
            : "Required"
        }
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        InputProps={{
          startAdornment: isFocused && (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: {
            color: fiUiCustomization.off_network_text_color,
          },
        }}
      />
    );
  };

  const renderConfirm = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "90vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "100vh",
          }}
        >
          <Typography variant="h6" textAlign={"center"}>
            Please confirm the details below:
          </Typography>
          <div style={{ fontSize: "18px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <b style={{ marginRight: "5px", textAlign: "right" }}>
                <div>Source card:</div>
                <div>Destination card:</div>
                <div>Amount:</div>
              </b>
              <div style={{ textAlign: "left" }}>
                <div>{sourceCard.number}</div>
                <div>{destinationCard.number}</div>
                <div> ${parseFloat(amount).toFixed(2)}</div>
              </div>
            </div>
          </div>
        </div>
        <Button
          style={{
            width: "100%",
            padding: "16px 0",
            textTransform: "none",
          }}
          onClick={() => setView("start")}
        >
          <b>Change Details</b>
        </Button>
        <Button
          style={{
            width: "100%",
            padding: "16px 0",
            textTransform: "none",
          }}
          onClick={() => submitForm()}
        >
          Confirm
        </Button>
      </div>
    );
  };

  const renderInitiated = () => {
    return (
      <>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "90vh",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography variant="h6">
              Payment has been initiated
              <br></br>from card{" "}
              {sourceCard.number.replace(/^.{12}/g, "************")}
              <br></br> to card{" "}
              {destinationCard.number.replace(/^.{12}/g, "************")}
              <br></br> in the amount of ${parseFloat(amount).toFixed(2)}.
            </Typography>
          </Box>
        </Grid>
      </>
    );
  };

  const renderSuccess = () => {
    return (
      <>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "90vh",
            textAlign: "center",
          }}
        >
          <Box>
            <Typography variant="h6">
              Congratulations! Payment was sent
              <br></br>from card{" "}
              {sourceCard.number.replace(/^.{12}/g, "************")}
              <br></br> to card{" "}
              {destinationCard.number.replace(/^.{12}/g, "************")}
              <br></br> in the amount of ${parseFloat(amount).toFixed(2)}.
              {/* <br></br>A confirmation email was sent to
              <br></br>temp@email.com */}
            </Typography>
          </Box>
        </Grid>
      </>
    );
  };

  const renderError = () => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "90vh",
          textAlign: "center",
        }}
      >
        <div style={{ height: "60px" }}></div>
        <Box>
          <Typography variant="h5">
            {errorMessage.split(".")[0]}.<br></br>
            {errorMessage.split(".")[1]}
          </Typography>
        </Box>
        <Button
          style={{
            width: "100%",
            padding: "16px 0",
            textTransform: "none",
          }}
          onClick={() => setView("start")}
        >
          <b>Change Details</b>
        </Button>
      </Grid>
    );
  };

  return (
    <div
      style={{
        backgroundColor: fiUiCustomization.off_network_bg_primary,
        maxWidth: "700px",
        margin: "10px auto",
      }}
    >
      {isMobile() ? (
        <FundingMobile
          view={view}
          sourceCard={sourceCard}
          destinationCard={destinationCard}
          setView={setView}
          handleSourceCardChange={handleSourceCardChange}
          handleDestinationCardChange={handleDestinationCardChange}
          setIsSubmitted={setIsSubmitted}
          cardIsValid={cardIsValid}
          amountIsValid={amountIsValid}
          renderCard={renderCard}
          renderAmountInput={renderAmountInput}
        />
      ) : (
        <>
          {view === "start" && (
            <FundingDesktop
              sourceCard={sourceCard}
              destinationCard={destinationCard}
              setView={setView}
              handleSourceCardChange={handleSourceCardChange}
              handleDestinationCardChange={handleDestinationCardChange}
              setIsSubmitted={setIsSubmitted}
              cardIsValid={cardIsValid}
              amountIsValid={amountIsValid}
              renderCard={renderCard}
              renderAmountInput={renderAmountInput}
            />
          )}
        </>
      )}
      {view === "confirm" && renderConfirm()}
      {view === "initiated" && renderInitiated()}
      {view === "success" && renderSuccess()}
      {view === "error" && renderError()}
    </div>
  );
};

export default FundingPage;
