import { useMemo } from "react";
import axios from "axios";

function useAPI(token: string, transferId: string) {
  const baseUrl = process.env.REACT_APP_P2C_API_URL;
  const http = useMemo(
    () =>
      axios.create({
        baseURL: transferId.length !== 36 ? `${baseUrl}/v2` : `${baseUrl}/v1`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      }),
    [token, baseUrl, transferId.length]
  );
  return http;
}

export default useAPI;
