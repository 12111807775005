import { createTheme } from "@mui/material/styles";
import queryString from "query-string";

const GlobalTheme = () => {
  const query_params = queryString.parse(window.location.search);

  const paymentMethod = {
    app: `${query_params.app}`,
    i: `${query_params.i}`,
    t: `${query_params.t}`,
    token: `${query_params.token}`,
    ui_version: `${query_params.ui_version}`,
  };

  let fiUiCustomization = {
    off_network_bg_primary: `#${query_params.off_network_bg_primary}`,
    off_network_bg_secondary: `#${query_params.off_network_bg_secondary}`,
    off_network_button_color: `#${query_params.off_network_button_color}`,
    off_network_text_color: `#${query_params.off_network_text_color}`,
  };

  let theme = createTheme({
    palette: {
      primary: {
        main: "#4a95fc",
        light: "#60b6ff",
        dark: "#1d3aab",
        contrastText: "#fff",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            border: "solid",
            borderWidth: "1px",
            textTransform: "none",
            margin: "16px 0",
            padding: "16px 0",
            width: "95%",
            lineHeight: "19px",
          },
        },
      },
    },
  });

  theme = createTheme({
    palette: {
      primary: {
        main: fiUiCustomization.off_network_text_color,
        dark: fiUiCustomization.off_network_bg_secondary,
        contrastText: "#ffffff",
      },
      secondary: {
        main: fiUiCustomization.off_network_button_color,
      },
      text: {
        primary: fiUiCustomization.off_network_text_color,
        secondary: fiUiCustomization.off_network_text_color,
      },
      background: {
        default: fiUiCustomization.off_network_bg_primary,
      },
      error: {
        main: fiUiCustomization.off_network_text_color,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            "&:disabled": {
              backgroundColor: fiUiCustomization.off_network_bg_primary,
              color: "#ffffff",
            },
            "&:hover": {
              backgroundColor:
                query_params.ui_version === "new"
                  ? fiUiCustomization.off_network_bg_primary
                  : fiUiCustomization.off_network_button_color,
              borderColor:
                query_params.ui_version === "new"
                  ? ""
                  : fiUiCustomization.off_network_bg_secondary,
              color:
                query_params.ui_version === "new"
                  ? query_params.app === "p2c"
                    ? ""
                    : fiUiCustomization.off_network_button_color
                  : fiUiCustomization.off_network_bg_secondary,
            },
            backgroundColor:
              query_params.ui_version === "new"
                ? query_params.app === "p2c"
                  ? fiUiCustomization.off_network_bg_primary
                  : fiUiCustomization.off_network_button_color
                : fiUiCustomization.off_network_bg_secondary,
            border: "solid",
            borderWidth: "1px",
            color:
              query_params.ui_version === "new"
                ? query_params.app === "p2c"
                  ? fiUiCustomization.off_network_text_color
                  : fiUiCustomization.off_network_bg_primary
                : fiUiCustomization.off_network_button_color,
            borderColor:
              query_params.ui_version === "new"
                ? fiUiCustomization.off_network_button_color
                : fiUiCustomization.off_network_text_color,
            textTransform: "none",
            margin: "16px 0",
            padding: "16px 0",
            width: "95%",
            lineHeight: "19px",
            borderRadius: query_params.ui_version === "new" ? "12px" : "",
            fontFamily:
              query_params.ui_version === "new" ? "Wix Madefor Display" : "",
            fontWeight: query_params.ui_version === "new" ? 500 : 700,
            fontSize: query_params.ui_version === "new" ? "16px" : "",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& label.Mui-focused": {
              borderWidth: "2px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor:
                  query_params.ui_version === "new"
                    ? fiUiCustomization.off_network_button_color
                    : fiUiCustomization.off_network_text_color,
              },
              "&:hover fieldset": {
                borderWidth: query_params.ui_version === "new" ? "1px" : "3px",
                borderColor:
                  query_params.ui_version === "new" &&
                  fiUiCustomization.off_network_button_color,
              },
              "&.Mui-focused fieldset": {
                borderWidth: query_params.ui_version === "new" ? "1px" : "2px",
              },
              borderRadius: query_params.ui_version === "new" ? "12px" : "",
              backgroundColor: fiUiCustomization.off_network_bg_primary,
            },
            "& .MuiFormHelperText-root": {
              "&.Mui-error": {
                fontWeight: 700,
              },
            },
            // this hides the arrows next to the input field
            input: {
              "&[type=number]": {
                MozAppearance: "textfield",
              },
              "&::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "&::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
            },
          },
        },
      },
    },
  });

  return { theme, paymentMethod, fiUiCustomization };
};
export default GlobalTheme;
