import React, { ChangeEventHandler } from "react";
import { Box, FormControl, TextField } from "@mui/material";

// For Credit cards
// ts-ignore below: The original react-credit-cards package is not compatible
// with React 18 and appears unmaintained. This fork is possibly the best
// updated one at this point, though it doesn't come with Type definitions.
// We are fine with this, as the package is current and maintained.
//
// @ts-ignore
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/es/styles-compiled.css";
import NumberFormat from "react-number-format";

interface NumberFormatCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

interface CardFields {
  name: string;
  number: string;
  cvc: string;
  expiry: string;
}

const CreditCardNumberFormatCustom = React.forwardRef(
  (props: NumberFormatCustomProps, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        // thousandSeparator
        isNumericString
        format="#### #### #### ####"
      />
    );
  }
);

function monthLimit(val: string, max: string) {
  if (val.length === 1 && val[0] > max[0]) {
    val = "0" + val;
  }
  if (val.length === 2) {
    if (Number(val) === 0) {
      val = "01";

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
}

function cardExpiry(val: string) {
  let month = monthLimit(val.substring(0, 2), "12");
  let year = val.substring(2, 4);

  return month + (year.length ? "/" + year : "");
}

const CreditCardExpDateFormatCustom = React.forwardRef(
  (props: NumberFormatCustomProps, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        onValueChange={(values: any) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format={cardExpiry}
        mask={["M", "M", "Y", "Y"]}
      />
    );
  }
);

const DebitCard = (
  cardFields: CardFields,
  eventHandler: ChangeEventHandler,
  isSubmitted: boolean
) => {
  const cardInput = (
    label: string,
    value: string | number,
    name: string,
    error: boolean,
    helperText: string,
    placeholder: string,
    inputComponent: any
  ) => {
    return (
      <Box>
        <FormControl style={{ width: "100%", margin: "3px auto" }}>
          <TextField
            required
            label={label}
            value={value}
            name={name}
            onChange={eventHandler}
            error={error && isSubmitted}
            helperText={error && isSubmitted ? `${helperText}` : "Required"}
            placeholder={placeholder}
            InputProps={{
              inputComponent: inputComponent,
            }}
          />
        </FormControl>
      </Box>
    );
  };
  return (
    <>
      {/* https://www.npmjs.com/package/react-credit-card-input */}
      {/* https://github.com/s-yadav/react-number-format */}
      <Cards
        cvc={cardFields.number}
        expiry={cardFields.expiry}
        name={cardFields.name}
        number={cardFields.number}
      />
      <div style={{ marginTop: "15px" }}>
        {cardInput(
          "Debit Card #",
          cardFields.number,
          "number",
          cardFields.number.length !== 16,
          "Please enter a valid debit card number",
          "•••• •••• •••• ••••",
          CreditCardNumberFormatCustom
        )}
        {cardInput(
          "Full Name",
          cardFields.name,
          "name",
          cardFields.name.length === 0,
          "Please enter name on card",
          "John Doe",
          null
        )}
        {cardInput(
          "Expiration Date",
          cardFields.expiry,
          "expiry",
          cardFields.expiry.length !== 4,
          "Please enter a valid expiration date",
          "MM/YY",
          CreditCardExpDateFormatCustom
        )}
      </div>
    </>
  );
};

export default DebitCard;
