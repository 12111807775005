import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router/AppRouter";
import GlobalTheme from "./theme";

function App() {
  const { theme, paymentMethod, fiUiCustomization } = GlobalTheme();

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRouter
          paymentMethod={paymentMethod}
          fiUiCustomization={fiUiCustomization}
        />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
