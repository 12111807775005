import CardInput from "./P2CInput";

interface P2CPageInterface {
  paymentMethod: {
    i: string;
    t: string;
    ui_version: string;
  };
  fiUiCustomization: {
    off_network_bg_primary: string;
    off_network_bg_secondary: string;
    off_network_button_color: string;
    off_network_text_color: string;
  };
}

export const P2CPage = ({
  paymentMethod,
  fiUiCustomization,
}: P2CPageInterface) => {
  const transferId = paymentMethod?.t as string;
  const inviteId = paymentMethod?.i as string;
  const ui_version = paymentMethod?.ui_version as string;

  const { renderP2CScreen } = CardInput(transferId, inviteId, ui_version);

  return <div>{renderP2CScreen(fiUiCustomization)}</div>;
};

export default P2CPage;
