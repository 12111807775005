import React, { ChangeEventHandler } from "react";
import { Button, FormControl } from "@mui/material";

interface CardFields {
  name: string;
  number: string;
  cvc: string;
  expiry: string;
}

interface FundingPageInterface {
  view: string;
  sourceCard: CardFields;
  destinationCard: CardFields;
  setView: React.Dispatch<React.SetStateAction<string>>;
  handleSourceCardChange: ChangeEventHandler;
  handleDestinationCardChange: ChangeEventHandler;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  cardIsValid: Function;
  amountIsValid: Function;
  renderCard: Function;
  renderAmountInput: Function;
}

export const FundingMobile = ({
  view,
  sourceCard,
  destinationCard,
  setView,
  handleSourceCardChange,
  handleDestinationCardChange,
  setIsSubmitted,
  cardIsValid,
  amountIsValid,
  renderCard,
  renderAmountInput,
}: FundingPageInterface) => {
  const nextPageButton = (nextPage: string, inputsValid: boolean) => {
    const clickNext = (nextPage: string, inputsValid: boolean) => {
      setIsSubmitted(true);
      if (inputsValid) {
        setView(nextPage);
        setIsSubmitted(false);
      }
    };
    return (
      <FormControl style={{ width: "100%" }}>
        <Button
          style={{ width: "100%" }}
          onClick={() => clickNext(nextPage, inputsValid)}
        >
          <b>Next</b>
        </Button>
      </FormControl>
    );
  };

  const mobileSpacing: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "90vh",
  };

  return (
    <form>
      {view === "start" && (
        <div style={mobileSpacing}>
          {renderCard("Funding Source", sourceCard, handleSourceCardChange)}
          {nextPageButton("destinationCard", cardIsValid(sourceCard))}
        </div>
      )}
      {view === "destinationCard" && (
        <div style={mobileSpacing}>
          {renderCard(
            "Payment Destination",
            destinationCard,
            handleDestinationCardChange
          )}
          {nextPageButton("amount", cardIsValid(destinationCard))}
        </div>
      )}
      {view === "amount" && (
        <div style={mobileSpacing}>
          <div style={{ height: "60px" }}></div>
          {renderAmountInput()}
          {nextPageButton("confirm", amountIsValid())}
        </div>
      )}
    </form>
  );
};

export default FundingMobile;
