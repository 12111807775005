import React, { ChangeEventHandler } from "react";
import { Box, Button, FormControl } from "@mui/material";

interface CardFields {
  name: string;
  number: string;
  cvc: string;
  expiry: string;
}

interface FundingPageInterface {
  sourceCard: CardFields;
  destinationCard: CardFields;
  setView: React.Dispatch<React.SetStateAction<string>>;
  handleSourceCardChange: ChangeEventHandler;
  handleDestinationCardChange: ChangeEventHandler;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  cardIsValid: Function;
  amountIsValid: Function;
  renderCard: Function;
  renderAmountInput: Function;
}

export const FundingDesktop = ({
  sourceCard,
  destinationCard,
  setView,
  handleSourceCardChange,
  handleDestinationCardChange,
  setIsSubmitted,
  cardIsValid,
  amountIsValid,
  renderCard,
  renderAmountInput,
}: FundingPageInterface) => {
  const clickConfirm = () => {
    setIsSubmitted(true);
    if (
      cardIsValid(sourceCard) &&
      cardIsValid(destinationCard) &&
      amountIsValid()
    ) {
      setView("confirm");
    }
  };

  return (
    <form>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "90vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          {renderCard("Funding Source", sourceCard, handleSourceCardChange)}
          {renderCard(
            "Payment Destination",
            destinationCard,
            handleDestinationCardChange
          )}
        </div>
        <Box textAlign={"center"}>
          <FormControl
            style={{
              width: "60%",
              margin: "5px auto",
              padding: "5px",
            }}
          >
            {renderAmountInput()}
          </FormControl>
        </Box>
        <Button
          style={{
            width: "100%",
            padding: "16px 0",
            textTransform: "none",
          }}
          onClick={clickConfirm}
        >
          <b>Submit</b>
        </Button>
      </div>
    </form>
  );
};

export default FundingDesktop;
